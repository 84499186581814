.box-legend{
  width: 30%;
  height: 20%;            
  position: absolute;
  top: 0;
  left: 0;
}
.legend{
    margin-bottom: 1px;
    margin-left : 1px;
    width: 200px;
    height:215px;
    background:rgba(83, 27, 147,0.0);
    color: white;
    position: fixed;
    bottom: 0;
    border-radius: 00px 30px 0px 0px;
    font-family: Tahoma;
} 
.legend-header{
  background: #1868B7;
  width: 75px;
  height: 7px;
  display: flex;
  align-items:center;
  justify-content: left;
  padding: 15px;
  border-radius: 0px 10px 0px 0px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.0) -2px -2px 8px 0px;
}
.legend-content{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px; 
  background:rgba(83, 27, 147,0.7);
  border-radius: 0px 10px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
}
.legend-column-label, .legend-column-content{
  float: left; 
  padding: 0px;
  min-height: 10px;
  box-sizing: border-box;
  font-family: Tahoma;
}
.legend-column-label {
  width: 15%; 
  justify-content: right;
  padding-right: 5px;
    font-size: medium;
}
.legend-column-content {
  width : 85%;
  text-align: left;
  padding-left: 8px;
  font-size: medium;
} 
.legend-row-1{
  padding: 7px 15px;
  color:white;
  border-radius: 0px 10px 0px 0px;
}
.legend-row-2{
  padding: 7px 15px;
  color:white;
}
.legend-row-3{
  padding: 7px 15px;
  color:white;
}
.legend-row-4{
  padding: 7px 15px;
  color:white;
}
.legend-footer{
  display: flex;
  align-items:center;
  justify-content:left;
  padding: 7px 15px;
  color:white;
  text-align: left;
  width : 130;
  height: 20px;
  font-size: x-small;
  background:rgba(83, 27, 147,0.3);
  border-radius: 0px 0px 10px 0px;
}
/* Tooltip container */
.legend-tooltip {
  position: relative;
  display: inline-block; 
}

/* Tooltip text */
.legend-tooltip .legend-tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 10px 10px;
  border-radius: 6px;
  font-weight: 400;
  font-size: smaller;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 155%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.legend-tooltip .legend-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 30%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent  #555 transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.legend-tooltip:hover .legend-tooltiptext {
  visibility: visible;
  opacity: 1;
}

  